
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Allgemeines',
  components: {
    //Modal,
  },
});

<style lang="scss">
.technische-daten {
  .tabelle-box {
    [class*='col-'] {
        justify-content: center;
        align-self:center;
    }

  }
  .kontak-box-all {
    .custom-link {
        display:block;
        font-size:130%;
        margin:10px 0;
        font-weight:500;
        color:$blue !important;
        &:hover {
          color:$blue-darker !important;
        }
      }
    .kontakt-box {
      
      p {
        margin:10px 0;
      }
      a {
        display:block;
        color:$blue !important;
        &:hover {
          color:$blue-darker !important;
        }
      }
      

    }
  }
}
</style>

<template>
  <div class="technische-daten content container">
    <h1>Technische Daten</h1>

    <p>Damit eine Übernahme Ihrer Daten problemlos funktioniert, finden Sie
nachfolgend einige technische Richtlinien für den Zeitungs- und Magazindruck.
Ein Tipp: Um nicht in Terminnot zu geraten, fragen Sie bei Unklarheiten lieber
gleich einen unserer
Spezialisten – sie helfen Ihnen gerne weiter.</p>
<p>Bitte geben Sie Ihren Ordnern, PDF-Dateien und Ihrem E-Mail-Betreff einen aussagekräftigen Namen
(z. B. Musterfirma-31-01-2020.pdf) mit <strong>Firmennamen</strong> und <strong>Erscheinungstag</strong> des Inserates.
Ansprechperson und Telefonnummer für Rückfragen bitte mitsenden. Sie erleichtern uns damit das schnelle
Auffinden Ihres Inserates im Empfangsordner. Weiters sollte auch auf dem Auftrag oder Manuskript der Betreff
bzw. Dateiname vermerkt sein. Sollten Sie kein PDF senden, komprimieren Sie bitte Ihre Daten (z. B. ZIP),
um eine sichere Übertragung zu gewährleisten und Übertragungszeit und -kosten zu sparen.</p>
     <div class="tabelle-box">
     
      
      <div class="row head-row hide-small">
        <div class="col-lg-4 text-align-left"></div>
        <div class="col-lg-4 text-align-left">Zeitung</div>
        <div class="col-lg-4 text-align-left">Magazin</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Auflösung der Graustufen- und Farbbilder</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 200 dpi bei 100 % Bildgröße</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 300 dpi bei 100 % Bildgröße</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Bitmap</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 600 dpi</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 800 dpi</div>
      </div>
       <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Farbprofil</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">WAN-IFRAnewspaper26v5.icc</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Innenteil: PSO_LWC_Improved_eci.icc<br>Umschlag (stärkeres Papier):ISOcoated_v2_300_eci.icc</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Linien</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">ZEITUNG</div>
        <div class="col-lg-4 col-6 text-right-small">Positiv min. 0,3 pt; negativ/gerastert min. 0,5 pt; keine Haarlinien</div>
        <div class="col-lg-4 col-6 text-align-left visible-small">MAGAZIN</div>
        <div class="col-lg-4 col-6 text-right-small">Mindestens 0,25 pt = 0,088 mm</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">Office-Daten</div>
        <div class="col-lg-8 col-12 text-right-small">Word, Excel, Powerpoint müssen in einem DTP-Programm nachgebaut werden. In Office-Dokumenten eingebettete
Logos und Bilder haben oft zu geringe Auflösung für Zeitungs- und Magazindruck bzw. beinhalten die Gefahr von
Farbverfremdung.</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-4 text-align-left strong992px">PDF-Standard</div>
        <div class="col-lg-8 col-12 text-right-small">Datenanlieferung als standardisiertes PDF/X-4
ICC-Profile-Download unter: www.styriamediadesign.at/service</div>
      </div>
    </div>

    <h2>Anlieferung Raumanzeigen</h2>
    <div class="row kontak-box-all">
      <div class="col-lg-6">
        <a href="mailto:meinewerbung@kleinezeitung.at" class="custom-link">meinewerbung@kleinezeitung.at</a>
        <a href="tel:+433168753781" class="custom-link">0316/875-3781</a>
        <div class="kontakt-box">
        <h3>Technische Auskünfte</h3>
        <strong>Styria Media Design GmbH &amp; Co KG</strong>
        <p>Dieter Bem, <a href="tel:+434635800405">0463/58 00-405</a></p>
        <p>Heimo Zmuegg, <a href="tel:+434635800350">0463/58 00-350</a></p>
        <p><a href="https://www.styriamediadesign.at/service" target="_blank">Styria Media Design - Service</a></p>
        </div>
      </div>
      <div class="col-lg-6">
        <a href="mailto:meinewerbung@kleinezeitung.at" class="custom-link">meinewerbung@kleinezeitung.at</a>
        <a href="tel:+433168753740" class="custom-link">0316/875-3740</a>
        <div class="kontakt-box">
        <h3>ANLIEFERUNG</h3>
        <strong>TECHNISCHE ABWICKLUNG &amp; KAMPAGNENBUCHUNG SOWIE NATIONALE VERMARKTUNG: styria digital one GmbH</strong>
        <p><a href="https://sdo.at/agb/" target="_blank">AGB:</a> Für nationale Buchungen gelten die Allgemeinen Geschäftsbedingungen der styria digital one.</p>
        <p><a href="https://sdo.at/allgemeine-buchungsbedingungen/" target="_blank">Allgemeine Buchungsbedingungen</a></p>
        </div>
      </div>
    </div>

    <h2>Technische Daten Digital</h2>
    <p>Spezifikationen für die Werbemittel, im Speziellen
für die Anlieferung von html5-Dateien, finden Sie unter:
<a href="https://www.iab-austria.at/" target="_blank">www.iab-austria.at</a></p>
    <p>Anlieferung: Standardwerbeformen müssen drei Werktage,
alle Sonderwerbeformen oder Unterlagen zur
Gestaltung von Online-Werbeformen müssen
fünf Werktage vor Erscheinen angeliefert werden.
Die Vorlaufzeit für Native-Advertising-Formate beläuft
sich auf mind. Zehn Werktage (ausgenommen Advertorials).</p>
<p>Akzeptierte Werbeformate:<br>
<ul class="content-ul">
  <li>jpg, png mit einer <strong>max. Dateigröße von 70 kb</strong></li>
  <li>gif, html5 mit einer <strong>max. Dateigröße von 150 kb</strong></li>
</ul>
</p>
<p>Weitere Informationen unter
  <a href="https://www.kleinezeitung.at/service/tarif/index.do" target="_blank">www.kleinezeitung.at/tarif</a>
  <a href="https://sdo.at/spezifikationen" target="_blank">sdo.at/spezifikationen</a>
  </p>



    <!-- -->
   

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/allgemeines/Technische-Daten.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Technische Daten
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>34 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <!-- <Modal>teadsfasdfasdfasst</Modal> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';

export default defineComponent({
  name: 'Allgemeines',
  components: {
    //Modal,
  },
});
</script>
